<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('course.certificate_wizard.third_step_info') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card header-classes="pb-0 " body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('course.certificate_data') }}</h4>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <ul class="list-group list-group-flush">
                <list-group-item-component :label="$t('fields.code')" :value="course.code"/>
                <list-group-item-component :label="$t('fields.price')" :value="course.price | euro"/>
              </ul>
            </div>
            <div class="col-12 col-md-6">
              <ul class="list-group list-group-flush">
                <list-group-item-component :label="$t('fields.title')" :value="course.title"/>
                <list-group-item-component
                  :label="$t('fields.active')"
                  :value="course.active ? $t('common.yes') : $t('common.no')"
                />
              </ul>
            </div>
            <div class="col-12 mx-2 mt-3">
              <div class="small text-uppercase">{{ $t('fields.description') }}</div>
              <p>{{ course.description }}</p>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card
          header-classes="pb-0"
          body-classes="standard-card-body"
          v-if="courses && courses.length > 0"
        >
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('page.courses') }}</h4>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <ul class="list-group list-group-flush">
                <list-group-item-component v-for="(course, index) in coursesChunks[0]" v-bind:key="`course-1-${index}`">
                  <template slot="label">
                    <div>
                      <label-theme-component>{{ course.code + ': ' }}</label-theme-component>
                      {{ course.title }}
                    </div>
                  </template>
                </list-group-item-component>
              </ul>
            </div>
            <div class="col-12 col-md-6">
              <ul class="list-group list-group-flush">
                <list-group-item-component v-for="(course, index) in coursesChunks[1]" v-bind:key="`course-2-${index}`">
                  <template slot="label">
                    <div>
                      <label-theme-component>{{ course.code + ': ' }}</label-theme-component>
                      {{ course.title }}
                    </div>
                  </template>
                </list-group-item-component>
              </ul>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Course from "@/models/course";
import BaseAlert from "@/components/BaseAlert";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {mapGetters} from "vuex";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "CertificateWizardThirdStep",
  components: {LabelThemeComponent, ListGroupItemComponent, BaseAlert},
  props: {
    course: {
      type: Object,
      default: () => Course
    },
    courses: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      allCourses: 'common/allCourses',
      users: 'common/users'
    }),

    coursesChunks() {
      return this.$_.chunk(this.courses, this.$_.ceil(this.courses.length / 2));
    }
  }
}
</script>

<style scoped>

</style>
