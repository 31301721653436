<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('course.certificate_wizard.second_step_info') }}</p>
      </div>
    </div>
    <certificate-courses-card ref="certificateCoursesCard" :number-items-per-page="24"/>
  </div>
</template>

<script>
import CertificateCoursesCard from "../components/CertificateCoursesCard";

export default {
  name: "CourseWizardSecondStep",
  components: {CertificateCoursesCard},
  methods: {
    getCourses() {
      return this.$refs.certificateCoursesCard.getSelectedCourses();
    },
  }
}
</script>

<style scoped>

</style>
