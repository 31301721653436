<template>
  <div class="row">
    <div class="col-md-12">
      <simple-wizard
        @tab-change="tabChange"
        @onCompleted="completeWizard"
        :finishButtonText="$t('common.finish')"
        :nextButtonText="$t('common.next')"
        :prevButtonText="$t('common.prev')"
      >
        <template slot="header">
          <h3 class="card-title">{{ $t('course.certificate_wizard.title') }}</h3>
          <h3 class="description">{{ $t('course.certificate_wizard.subtitle') }}</h3>
        </template>

        <wizard-tab :before-change="() => validateStep(1)">
          <template slot="label">
            <octo-icon icon="list"></octo-icon>
            <p>{{ $t('course.certificate_wizard.form') }}</p>
          </template>
          <certificate-wizard-first-step ref="certificateWizardFirstStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(2)">
          <template slot="label">
            <octo-icon icon="filter"></octo-icon>
            <p>{{ $t('course.certificate_wizard.courses') }}</p>
          </template>
          <certificate-wizard-second-step ref="certificateWizardSecondStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(4)">
          <template slot="label">
            <octo-icon icon="checked"></octo-icon>
            <p>{{ $t('course.certificate_wizard.report') }}</p>
          </template>
          <certificate-wizard-third-step ref="certificateWizardThirdStep" :course="course" :courses="courses"/>
        </wizard-tab>
      </simple-wizard>
    </div>
  </div>
</template>

<script>

import {endpoints} from "@/routes/endpoints";
import SimpleWizard from "@/components/Wizard/Wizard";
import WizardTab from "@/components/Wizard/WizardTab";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import CertificateWizardFirstStep from "@/pages/Courses/certificate-wizard/CertificateWizardFirstStep";
import CertificateWizardSecondStep from "@/pages/Courses/certificate-wizard/CertificateWizardSecondStep";
import CertificateWizardThirdStep from "@/pages/Courses/certificate-wizard/CertificateWizardThirdStep";
import Course from "@/models/course";
import {mapMutations} from "vuex";
import {MessageBox} from "element-ui";

export default {
  name: "CertificateWizardPage",
  components: {
    OctoIcon,
    WizardTab,
    SimpleWizard,
    CertificateWizardFirstStep,
    CertificateWizardSecondStep,
    CertificateWizardThirdStep
  },
  data() {
    return {
      course: this.$_.cloneDeep(Course),
      courses: [],
    }
  },
  methods: {
    ...mapMutations({
      updateCourses: 'common/updateCourses'
    }),

    async validateStep(step) {
      let check = false;
      switch (step) {
        case 1:
          const data = await this.$refs.certificateWizardFirstStep.validate();
          if (data) {
            this.course = data;
            check = true;
          }
          break;
        case 2:
        case 3:
          check = true;
          break;
        default:
          check = false;
          break;
      }

      if (!check) {
        this.$notify({type: 'danger', message: this.$t('notifications.wizard_step_error')});
      }

      return check;
    },
    tabChange(oldTab, newTab) {
      switch (newTab.tabId) {
        case '0':
        case '1':
          break;
        case '2':
          this.courses = this.$refs.certificateWizardSecondStep.getCourses();
          break;
        default:
          break;
      }
    },
    completeWizard() {
      const courseData = this.course;
      courseData.type = 'certificate';
      courseData.courseIds = this.$_.map(this.courses, course => course.id);

      this.$api.post(endpoints.COURSE_STORE, courseData)
        .then((resp) => {
          this.updateCourses(resp?.data?.data);
          MessageBox({
            title: this.$t('common.good_job'),
            message: this.$t('course.certificate_wizard.show_new_certificate'),
            confirmButtonText: this.$t('common.ok'),
            type: 'success'
          })
            .finally(() => {
              this.$router.push({name: 'courses.certificates.show', params: {id: resp?.data?.data?.id}});
            });
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
    }
  }
}
</script>

<style scoped>

</style>
