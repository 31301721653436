<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('course.certificate_wizard.first_step_title') }}</p>
        <p>{{ $t('course.certificate_wizard.first_step_subtitle') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <certificate-form-card :course="course" ref="certificateForm"/>
      </div>
    </div>
  </div>
</template>

<script>
import Course from "@/models/course";
import CertificateCoursesCard from "../components/CertificateCoursesCard";
import CourseTutorsCard from "../components/CourseTutorsCard";
import CertificateFormCard from "../components/CertificateFormCard";

export default {
  name: "CourseWizardFirstStep",
  components: {CertificateFormCard, CourseTutorsCard, CertificateCoursesCard},
  data() {
    return {
      course: this.$_.cloneDeep(Course)
    }
  },
  methods: {
    validate() {
      return this.$refs.certificateForm.validate();
    },
  }
}
</script>

<style scoped>

</style>
